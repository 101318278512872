import { IShipmentRequest } from '@/models/shipmentRequest';
import axios from 'axios';
import {
  IFetchShipmentParams,
  IInvoicePayload,
  Shipment
} from '../../models/shipment';

export interface IFetchManifestParams {
  start_date: string;
  end_date: string;
  warehouse: string;
}

export class ShipmentService {
  private endpoint: string;

  constructor() {
    this.endpoint = ' https://ournationexpress.com/api/v1/package';
  }

  async fetchUnassignedShipments() {
    return axios
      .get(`${this.endpoint}/unassigned/`)
      .then((response) => response.data);
  }

  async fetchShipmentRequests() {
    return axios
      .get(`${this.endpoint}/request/`)
      .then((response) => response.data);
  }

  async createShipmentRequest(request: IShipmentRequest) {
    return axios
      .post<IShipmentRequest>(`${this.endpoint}/request/`, request)
      .then((response) => response.data);
  }

  async updateShipmentRequest(request: IShipmentRequest) {
    return axios
      .patch<IShipmentRequest>(
        `${this.endpoint}/request/${request.id}/`,
        request
      )
      .then((response) => response.data);
  }

  async fetchShipments(params?: IFetchShipmentParams) {
    return axios
      .get(`${this.endpoint}/`, { params: params })
      .then(({ data }: any) => data);
  }

  async fetchManifest(param: IFetchManifestParams) {
    return axios
      .get(`${this.endpoint}/manifest`, {
        params: {
          'start-date': param.start_date + 'T00:00',
          'end-date': param.end_date + 'T23:59',
          warehouse: param.warehouse
        }
      })
      .then((response) => response.data);
  }

  async updateManifest(payload: Shipment) {
    return axios
      .patch<Shipment>(`${this.endpoint}/manifest/${payload.pk}/`, payload)
      .then(({ data }) => data);
  }

  async exportManifest(param: IFetchManifestParams) {
    return axios
      .get(`${this.endpoint}/manifest/export`, {
        params: param
      })
      .then((response) => response.data);
  }

  async trackShipments(tracking_number: string) {
    return axios
      .get(`${this.endpoint}/tracking_number/?search=${tracking_number}/`)
      .then((response) => response.data);
  }

  async createShipment(payload: Shipment) {
    return axios
      .post<Shipment>(`${this.endpoint}/`, payload)
      .then(({ data }) => data);
  }

  async updateShipment(payload: any) {
    return axios
      .patch<Shipment>(`${this.endpoint}/${payload.id}/`, payload.data)
      .then(({ data }) => data);
  }

  async deleteShipment(payload: any) {
    return axios.delete(`${this.endpoint}/${payload.id}/`);
  }

  async uploadInvoice(payload: FormData) {
    axios
      .post(`https://ournationexpress.com/api/v1/invoice_image/`, payload)
      .then(({ data }) => data);
  }

  async generatePDFInvoice(payload: IInvoicePayload) {
    return axios
      .post<Shipment>(`https://ournationexpress.com/api/v1/invoice/`, payload)
      .then(({ data }) => data);
  }
}

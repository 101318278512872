import axios from 'axios';
import { Franchise } from '@/models/franchise';

export class FranchiseService {
  private endpoint: string;

  constructor() {
    this.endpoint = ' https://ournationexpress.com/api/v1/franchise';
  }

  async createFranchise(franchise: Franchise) {
    return axios
      .post<Franchise>(`${this.endpoint}/`, franchise)
      .then(({ data }) => data);
  }

  async fetchUserFranchises() {
    return axios
      .get<Franchise[]>(`${this.endpoint}/names/`)
      .then(({ data }) => data);
  }

  async fetchFranchises() {
    return axios
      .get<Franchise[]>(`${this.endpoint}/`, {
        headers: {
          Authorization: null
        }
      })
      .then(({ data }) => data);
  }

  async fetchFranchise(franchise: Franchise) {
    return axios
      .get<Franchise>(`${this.endpoint}${franchise}/items/${franchise.pk}/`)
      .then(({ data }) => data);
  }

  async updateFranchise(franchise: Franchise) {
    return axios
      .put<Franchise>(`${this.endpoint}/${franchise.pk}/`, franchise)
      .then(({ data }) => data);
  }

  async deleteFranchise(franchise: Franchise) {
    return axios.delete(`${this.endpoint}/${franchise.pk}/`);
  }
}

import axios from 'axios';
import { UserAddress } from '@/models/user_address';

export class UserAddressService {
  private endpoint: string;

  constructor() {
    this.endpoint =
      ' https://ournationexpress.com/api/v1/user_delivery_address';
  }

  async createUserAddress(UserAddress: any) {
    return axios
      .post<UserAddress>(`${this.endpoint}/`, UserAddress)
      .then(({ data }) => data);
  }

  async fetchUserAddresses() {
    return axios.get<UserAddress>(`${this.endpoint}/`).then(({ data }) => data);
  }

  async updateUserAddress(userAddress: UserAddress) {
    return axios
      .put<UserAddress>(`${this.endpoint}/${userAddress.pk}/`, userAddress)
      .then(({ data }) => data);
  }

  async deleteUserAddress(userAddress: UserAddress) {
    return axios.delete(`${this.endpoint}/${userAddress.pk}/`);
  }
}

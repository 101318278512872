export class TokenService {
  public static REFRESH_TOKEN_THRESHOLD_IN_MINUTES = 45;

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent(window.escape(window.atob(output)));
  }

  public decodeToken(token: string): JSON | { upn: string } | any {
    if (token === null || token === '') {
      return { upn: '' };
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  public isExpired(exp: number): boolean {
    try {
      return !(Date.now() >= exp * 1000);
    } catch (e) {
      return false;
    }
  }

  public needsRefresh(expiration_timestamp: number): boolean {
    const time_left = expiration_timestamp * 1000 - Date.now();
    const time_left_in_seconds = time_left / 1000;
    const time_left_in_minutes = time_left_in_seconds / 60;

    //time left must be less than the refresh threshold but greater than the expiration threshold
    return (
      time_left_in_minutes < TokenService.REFRESH_TOKEN_THRESHOLD_IN_MINUTES &&
      time_left_in_minutes > 0
    );
  }

  public get getEvent(): string | boolean {
    return localStorage.getItem('current_event') || false;
  }
  public setEvent(event: string): void {
    localStorage.setItem('current_event', event);
  }
  public removeEvent(): void {
    localStorage.removeItem('current_event');
  }
  public get getToken(): string | boolean {
    return localStorage.getItem('access_token') || false;
  }
  public setToken(token: string, tokenData?: string): void {
    localStorage.setItem('access_token', token);
    if (tokenData != undefined) {
      localStorage.setItem('user', JSON.stringify(tokenData));
    }
  }
  public setRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }
  public setExpireDate(event: string): void {
    localStorage.setItem('exxpire_date', event);
  }
  public removeToken(): void {
    localStorage.removeItem('access_token');
  }
}

import axios from 'axios';
import { Account } from '../../models/account';

export class AccountService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'https://ournationexpress.com/api/v1/users';
  }

  async fetchAccount(id: number) {
    return axios
      .get<Account[]>(`${this.endpoint}/${id}/`)
      .then(({ data }: any) => data);
  }
}

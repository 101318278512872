import axios from 'axios';
import { Address } from '@/models/address';

export class AddressService {
  private endpoint: string;
  private userEndpoint: string;

  constructor() {
    this.endpoint = ' https://ournationexpress.com/api/v1/address';
    this.userEndpoint =
      ' https://ournationexpress.com/api/v1/user_delivery_address';
  }

  async createPickupAddress(address: any) {
    return axios
      .post<Address>(`${this.endpoint}/`, address)
      .then(({ data }) => data);
  }

  async createUserAddress(address: any) {
    return axios
      .post<Address>(`${this.userEndpoint}/`, address)
      .then(({ data }) => data);
  }

  async createAddress(address: Address) {
    const loginUser = JSON.parse(localStorage.getItem('user')!);
    const userId = loginUser == null ? null : loginUser.pk;
    address.user = userId;
    return axios
      .post<Address>(`${this.endpoint}/`, address)
      .then(({ data }) => data);
  }

  async fetchAddress() {
    return axios.get<Address>(`${this.endpoint}/`).then(({ data }) => data);
  }

  async updateAddress(address: Address) {
    return axios
      .put<Address>(`${this.endpoint}/${address.pk}/`, address)
      .then(({ data }) => data);
  }

  async deleteAddress(address: Address) {
    return axios.delete(`${this.endpoint}/${address.pk}/`);
  }
}

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import {
  IFetchShipmentParams,
  IInvoicePayload,
  Shipment
} from '@/models/shipment';
import { ShipmentService } from '@/services/shipments/index';
import { IShipmentRequest } from '@/models/shipmentRequest';
import { IFetchManifestParams } from '@/services/shipments/service';

const shipmentService = new ShipmentService();

export const state = {
  count: 0,
  lastPromise: null,
  shipment: [] as Shipment[],
  trackingShipment: [] as Shipment[],
  unassignedShipment: [] as Shipment[],
  shipmentRequest: [] as IShipmentRequest[],
  manifest: [] as Shipment[]
};

export type ShipmentState = typeof state;

export const mutations: MutationTree<ShipmentState> = {
  SET_COUNT(state: any, payload: number): void {
    state.count = payload;
  },

  INCREMENT_COUNT(state: any): void {
    state.count += 1;
  },

  DECREMENT_COUNT(state: any): void {
    state.count -= 1;
  },

  SET_LAST_PROMISE(state: any, payload: Promise<any>): void {
    state.lastPromise = payload;
  },

  SET_SHIPMENTS(state: any, payload: Shipment[]) {
    state.shipment = payload;
  },

  SET_MANIFEST(state: any, payload: Shipment[]) {
    state.manifest = payload;
  },

  ADD_MANIFEST(state: any, payload: Shipment) {
    state.manifest.push(payload);
  },

  UPDATE_MANIFEST(state: any, payload: Shipment) {
    const index = state.manifest.findIndex(
      (model: Shipment) => model.pk === payload.pk
    );
    state.manifest.splice(index, 1, payload);
  },

  SET_UNASSIGNED_SHIPMENTS(state: any, payload: Shipment[]) {
    state.unassignedShipment = payload;
  },

  SET_TRACKING_SHIPMENT(state: any, payload: any) {
    state.trackingShipment = payload;
  },

  SET_SHIPMENT_REQUESTS(state: any, payload: IShipmentRequest[]) {
    state.shipmentRequest = payload;
  },

  ADD_SHIPMENT_REQUEST(state: any, payload: IShipmentRequest) {
    state.shipmentRequest.push(payload);
  },

  UPDATE_SHIPMENT_REQUEST(state: any, payload: IShipmentRequest) {
    const index = state.shipmentRequest.findIndex(
      (model: IShipmentRequest) => model.id === payload.id
    );
    state.shipmentRequest.splice(index, 1, payload);
  },

  ADD_SHIPMENT(state: any, payload: Shipment) {
    state.shipment.push(payload);
  },

  UPDATE_SHIPMENT(state: any, payload: Shipment) {
    const index = state.shipment.findIndex(
      (model: Shipment) => model.id === payload.id
    );
    state.shipment.splice(index, 1, payload);
  },

  REMOVE_SHIPMENT(state: any, payload: number) {
    const index = state.shipment.findIndex(
      (model: Shipment) => model.id === payload
    );
    state.shipment.splice(index, 1);
  }
};

export const actions: ActionTree<ShipmentState, ShipmentState> = {
  uploadInvoice: async ({ commit }: any, payload: FormData) =>
    shipmentService.uploadInvoice(payload),

  generatePDFInvoice: async ({ commit }: any, payload: IInvoicePayload) =>
    shipmentService.generatePDFInvoice(payload).then((data) => {
      return data;
    }),

  fetchUnassignedShipments: async ({ commit }: any) =>
    shipmentService.fetchUnassignedShipments().then((data) => {
      commit('SET_UNASSIGNED_SHIPMENTS', data);
    }),

  fetchShipmentRequests: async ({ commit }: any) =>
    shipmentService.fetchShipmentRequests().then((data) => {
      commit('SET_SHIPMENT_REQUESTS', data);
    }),

  createShipmentRequest: async ({ commit }: any, payload: IShipmentRequest) =>
    shipmentService
      .createShipmentRequest(payload)
      .then((model: IShipmentRequest) => commit('ADD_SHIPMENT_REQUEST', model)),

  updateShipmentRequest: async (
    { state, commit }: any,
    payload: IShipmentRequest
  ) =>
    shipmentService
      .updateShipmentRequest(payload)
      .then((model: IShipmentRequest) => {
        const index = state.shipmentRequest.findIndex(
          (v: IShipmentRequest) => v.id === model.id
        );
        if (index === -1) {
          commit('ADD_SHIPMENT_REQUEST', model);
        } else {
          commit('UPDATE_SHIPMENT_REQUEST', model);
        }
      }),

  fetchShipments: async (
    { state, commit }: any,
    params?: IFetchShipmentParams
  ) => {
    const promise = shipmentService.fetchShipments(params);

    commit('SET_LAST_PROMISE', promise);
    return promise.then((data) => {
      commit('SET_COUNT', data.count);
      commit('SET_SHIPMENTS', data.results);
    });
  },

  fetchManifest: async ({ commit }: any, payload: IFetchManifestParams) =>
    shipmentService.fetchManifest(payload).then((data) => {
      commit('SET_MANIFEST', data);
    }),

  updateManifest: async ({ state, commit }: any, payload: Shipment) =>
    shipmentService.updateManifest(payload).then((model: Shipment) => {
      const index = state.manifest.findIndex(
        (v: Shipment) => v.pk === model.pk
      );
      if (index === -1) {
        commit('ADD_MANIFEST', model);
      } else {
        commit('UPDATE_MANIFEST', model);
      }
    }),

  exportManifest: async ({ commit }: any, payload: IFetchManifestParams) =>
    shipmentService.exportManifest(payload),

  trackShipments: async ({ commit }: any, payload: string) =>
    shipmentService.trackShipments(payload).then((data) => {
      commit('SET_TRACKING_SHIPMENT', data);
      return data;
    }),

  createShipment: async ({ commit }: any, payload: Shipment) =>
    shipmentService.createShipment(payload).then((data: Shipment) => {
      commit('ADD_SHIPMENT', data);
      return data;
    }),

  updateShipment: async (
    { state, commit }: any,
    payload: { id: number; data: any }
  ) =>
    shipmentService.updateShipment(payload).then((model: Shipment) => {
      const index = state.shipment.findIndex(
        (v: Shipment) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_SHIPMENT', model);
      } else {
        commit('UPDATE_SHIPMENT', model);
      }
    }),

  deleteShipment: async ({ state, commit }: any, payload: Shipment) =>
    shipmentService.deleteShipment(payload).then(() => {
      commit('REMOVE_SHIPMENT', payload.id);
    })
};

export const getters: GetterTree<ShipmentState, ShipmentState> = {
  getCurrentShipments: (state: ShipmentState) => () => {
    const filter = state.shipment.filter((model) => model.status !== 'P');
    return filter;
  },
  getAllShipments: (state: ShipmentState) => () => {
    return state.shipment;
  },
  getShipmentCount(state: ShipmentState): number {
    return state.count;
  },
  getAllShipmentRequests: (state: ShipmentState): IShipmentRequest[] => {
    return state.shipmentRequest;
  }
};

'use strict';

import Vue from 'vue';
import axios, { AxiosStatic, AxiosResponse } from 'axios';
import { TokenService } from '@/services/token';
import { AuthService } from '@/services/auth/service';

// Add a request interceptor
axios.interceptors.request.use(function(config) {
  const tokenService: TokenService = new TokenService();

  const needsRefresh = tokenService.needsRefresh(
    tokenService.decodeToken(localStorage.getItem('access_token') || '').exp
  );

  if (needsRefresh) {
    const authService = new AuthService();
    authService.refreshToken(localStorage.getItem('refresh_token')!);
  }

  const access_token = localStorage.getItem('access_token');
  if (
    (config.url?.includes('/address') ||
      config.url?.includes('user_delivery_address')) &&
    config.method == 'post'
  ) {
    return config;
  }

  const token = `Bearer ${access_token}`;

  if (!config.url?.includes('franchise/names')) {
    config.headers.Authorization = token;
  }
  return config;
});

// axios.defaults.headers.common['Authorization'] = token;
const config = {
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_API_URL
      : process.env.VUE_APP_DEV_API_URL || '',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  headers: {
    'Content-Type': 'application/json',
  },
};
const _axios = axios.create(config);

Vue.use({
  install() {
    Vue.prototype.$axios = _axios;
  },
});

declare module 'vue/types/vue' {
  interface Vue {
    axios: AxiosStatic;
  }
}

export { _axios as axios, AxiosResponse };

import axios from 'axios';
import { PreAlert } from '@/models/preAlert';

export class PreAlertService {
  private endpoint: string;
  private userId!: string;

  constructor() {
    this.endpoint = ' https://ournationexpress.com/api/v1/preAlert';
  }

  async createPreAlert(preAlert: PreAlert) {
    return axios
      .post<PreAlert>(`${this.endpoint}/`, preAlert)
      .then(({ data }) => data);
  }

  async fetchPreAlerts() {
    return axios.get<PreAlert[]>(`${this.endpoint}/`).then(({ data }) => data);
  }

  async fetchPreAlert(preAlert: PreAlert) {
    return axios
      .get<PreAlert>(`${this.endpoint}${preAlert}/items/${preAlert.id}/`)
      .then(({ data }) => data);
  }

  async updatePreAlert(preAlert: PreAlert) {
    return axios
      .put<PreAlert>(`${this.endpoint}/${preAlert.id}/`, preAlert)
      .then(({ data }) => data);
  }

  async deletePreAlert(preAlert: PreAlert) {
    return axios.delete(`${this.endpoint}/${preAlert.id}/`);
  }
}

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import {
  INotification,
  INotificationRecipients,
  Notification,
} from '@/models/notification';
import { NotificationService } from '@/services/notifications/index';
import { Account } from '@/models/account';

const notificationService = new NotificationService();

export const state = {
  notification: [] as Notification[],
  user: JSON.parse(window.localStorage.getItem('user')!),
};

export type NotificationState = typeof state;

export const mutations: MutationTree<NotificationState> = {
  SET_NOTIFICATIONS(state: any, payload: Notification[]) {
    state.notification = payload;
  },

  ADD_NOTIFICATION(state: any, payload: Notification) {
    state.notification.push(payload);
  },

  UPDATE_NOTIFICATION(state: any, payload: Notification) {
    const index = state.notification.findIndex(
      (model: Notification) => model.pk === payload.pk
    );
    state.notification.splice(index, 1, payload);
  },

  REMOVE_NOTIFICATION(state: any, payload: number) {
    const index = state.notification.findIndex(
      (model: Notification) => model.pk === payload
    );
    state.notification.splice(index, 1);
  },
};

export const actions: ActionTree<NotificationState, NotificationState> = {
  fetchNotifications: async ({ commit }: any, payload: any) =>
    notificationService.fetchNotifications().then((data) => {
      commit('SET_NOTIFICATIONS', data);
    }),

  createNotification: async ({ commit }: any, payload: Notification) =>
    notificationService
      .createNotification(payload)
      .then((model: Notification) => commit('ADD_NOTIFICATION', model)),

  updateNotification: async ({ state, commit }: any, payload: Notification) =>
    notificationService
      .updateNotification(payload)
      .then((model: Notification) => {
        const index = state.notification.findIndex(
          (v: Notification) => v.pk === model.pk
        );
        if (index === -1) {
          commit('ADD_NOTIFICATION', model);
        } else {
          commit('UPDATE_NOTIFICATION', model);
        }
      }),

  deleteNotification: async ({ state, commit }: any, payload: Notification) =>
    notificationService.deleteNotification(payload).then(() => {
      commit('REMOVE_NOTIFICATION', payload);
    }),
};

export const getters: GetterTree<NotificationState, NotificationState> = {
  getUnReadNotifications: (state: NotificationState): INotification[] => {
    const unread = state.notification.filter((notif: INotification) => {
      const recip = (notif.recipients as INotificationRecipients[])!.find(
        (recipient: INotificationRecipients) =>
          (recipient.user! as Account).pk == state.user!.pk
      );
      if (recip && !recip.is_read) {
        return notif;
      }
    });
    return unread;
  },

  getReadNotifications: (state: NotificationState): INotification[] => {
    return state.notification.filter((notif: INotification) => {
      const recip = (notif.recipients as INotificationRecipients[]).find(
        (recipient: INotificationRecipients) =>
          (recipient.user! as Account).pk == state.user!.pk
      );

      if (recip && recip.is_read) {
        return notif;
      }
    });
  },
};

import { TokenService } from '@/services/token';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authMeta } from './meta';
import { AuthGuard, layout, root, route, subRoute } from './utils';

Vue.use(VueRouter);

const routes = root([
  {
    path: '',
    redirect: { name: 'current-shipments' }
  },

  route('home', 'home', [
    subRoute({
      path: '',
      name: 'home',
      component: 'views/home/HomePage',
      meta: { label: 'FAQ' }
    })
  ]),

  route('faq', 'faq', [
    subRoute({
      path: '',
      name: 'faq',
      component: 'views/faq/FAQPage',
      meta: { label: 'FAQ' }
    })
  ]),

  layout('auth', 'Auth', [
    route('', 'Auth', [
      subRoute({
        path: 'rates-list/:pagetype',
        name: 'rates-list',
        component: 'views/rates/RatesListPage'
      }),

      subRoute({
        path: 'login',
        name: 'login',
        component: 'views/auth/Login'
      }),

      subRoute({
        path: 'sign-up',
        name: 'sign-up',
        component: 'views/auth/SignUp'
      }),

      subRoute({
        path: 'forget-password',
        name: 'forget-password',
        component: 'views/auth/ForgetPassword'
      }),

      subRoute({
        path: 'reset-password/:token',
        name: 'reset-password',
        component: 'views/auth/ResetPassword'
      }),

      subRoute({
        path: 'verification',
        name: 'verification',
        component: 'views/auth/Verification'
      }),

      subRoute({
        path: 'confirmation-message',
        name: 'confirmationMessage',
        component: 'views/auth/ConfirmationMessage'
      })
    ])
  ]),

  layout('staff-portal', 'staff', [
    route(
      'staff-dashboard',
      'staffdashboard',
      [
        subRoute({
          path: '',
          name: 'staff-dashboard',
          component: 'components/staffdashboard/StaffDashboard',
          meta: { label: 'Staff Dashboard' }
        })
      ],
      authMeta
    ),

    route(
      'package-manager',
      'staffpackagemanager',
      [
        subRoute({
          path: 'package-manager',
          name: 'package-manager',
          component: 'components/staffpackagemanager/StaffPackageManager',
          meta: { label: 'Package Manager' }
        }),
        subRoute({
          path: 'unassigned-packages',
          name: 'unassigned-packages',
          component:
            'components/staffpackagemanager/staffunassignedpackage/StaffUnassignedPackageLIst',
          meta: { label: 'Unassigned Package' }
        }),
        subRoute({
          path: 'package-requests',
          name: 'package-requests',
          component:
            'components/staffpackagemanager/staffpackagerequest/StaffPackageRequestList',
          meta: { label: 'Package Manager' }
        }),
        subRoute({
          path: 'package-manifest',
          name: 'package-manifest',
          component:
            'components/staffpackagemanager/staffpackagemanifest/StaffPackageManifestList',
          meta: { label: 'Package Manager' }
        })
      ],
      authMeta
    ),

    route(
      'user-manager',
      'staffusermanager',
      [
        subRoute({
          path: '',
          name: 'user-manager',
          component: 'components/staffusermanager/StaffUserManager',
          meta: { label: 'User Manager' }
        })
      ],
      authMeta
    ),

    route(
      'prealert-manager',
      'staffprealertmanager',
      [
        subRoute({
          path: '',
          name: 'preAlert-manager',
          component: 'components/staffprealertmanager/StaffPreAlertList',
          meta: { label: 'Pre-Alert Manager' }
        })
      ],
      authMeta
    ),

    route(
      'franchise-manager',
      'stafffranchisemanager',
      [
        subRoute({
          path: '',
          name: 'franchise-manager',
          component: 'components/stafffranchisemanager/StaffFranchiseList',
          meta: { label: 'Franchise Manager' }
        })
      ],
      authMeta
    ),

    route(
      'notification',
      'staffnotification',
      [
        subRoute({
          path: '',
          name: 'notification',
          component: 'views/staffnotification/StaffNotification',
          meta: { label: 'Notification' }
        })
      ],
      authMeta
    )
  ]),

  layout('', 'app', [
    route(
      'accounts',
      'accounts',
      [
        subRoute({
          path: 'user-account',
          name: 'user-account',
          component: 'views/accounts/Index',
          meta: { label: 'User Account' }
        })
      ],
      authMeta
    ),

    route(
      'dashboard',
      'dashboard',
      [
        subRoute({
          path: '',
          name: 'current-shipments',
          component: 'components/dashboard/CurrentShipments',
          meta: { label: 'Current Shipments' }
        }),
        subRoute({
          path: 'past-shipments',
          name: 'past-shipments',
          component: 'components/dashboard/PastShipments',
          meta: { label: 'Past Shipments' }
        }),
        subRoute({
          path: 'mail-pending',
          name: 'mail-pending',
          component: 'components/dashboard/MailPending',
          meta: { label: 'Mail Pending' }
        }),
        subRoute({
          path: 'pre-alerts',
          name: 'pre-alerts',
          component: 'components/dashboard/PreAlerts',
          meta: { label: 'Pre Alerts' }
        })
      ],
      authMeta
    ),

    route('billings', 'billings', [
      subRoute({
        path: 'billingsPage',
        name: 'billings',
        component: 'views/billings/Index',
        meta: { label: 'Billings' }
      })
    ]),

    route('shippingrates', 'shippingrates', [
      subRoute({
        path: 'shippingratesPage',
        name: 'shippingrates',
        component: 'views/shippingrates/Index',
        meta: { label: 'Shipping Rates' }
      })
    ]),

    route(
      'secondaryusers',
      'secondaryusers',
      [
        subRoute({
          path: 'list',
          name: 'sencondary-user-list',
          component: 'views/secondaryusers/Index',
          meta: { label: 'Secondary User' }
        })
      ],
      authMeta
    ),

    route(
      'authorizedpickupperson',
      'authorizedpickupperson',
      [
        subRoute({
          path: 'authorizedpickupperson',
          name: 'authorizedpickupperson',
          component: 'views/authorizedpickupperson/AuthorizedPickupPerson',
          meta: { label: 'Authorized Pickup Person' }
        })
      ],
      authMeta
    ),

    route(
      'contactus',
      'contactus',
      [
        subRoute({
          path: 'contactus',
          name: 'contactus',
          component: 'views/contactus/ContactUs',
          meta: { label: 'Contact Us' }
        })
      ],
      authMeta
    ),

    route(
      'settings',
      'settings',
      [
        subRoute({
          path: 'settingsPage',
          name: 'settings',
          component: 'views/settings/Index',
          meta: { label: 'Settings' }
        })
      ],
      authMeta
    )
  ]),

  subRoute({
    path: '404',
    name: '404',
    component: 'views/404/404',
    meta: { label: '404' }
  })
]);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const tokenService = new TokenService();

AuthGuard(router, tokenService);

export default router;

import axios from 'axios';
import { Account, IAccountParams } from '../../models/account';

export class UserService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'https://ournationexpress.com/api/v1/users';
  }

  async fetchSuperAdminUsers() {
    return axios
      .get<Account[]>(`${this.endpoint}/super_admins`)
      .then(({ data }: any) => data);
  }

  async fetchUsers(params?: IAccountParams) {
    return axios
      .get<Account[]>(`${this.endpoint}/`, {
        params: params
      })
      .then(({ data }: any) => data);
  }

  async fetchUser(id: number) {
    return axios
      .get<Account[]>(`${this.endpoint}/${id}/`)
      .then(({ data }: any) => data);
  }

  async updateUser(payload: any) {
    return axios
      .patch<Account>(`${this.endpoint}/${payload.pk}/`, payload.data)
      .then(({ data }) => data);
  }

  async deleteUser(payload: any) {
    return axios.delete(`${this.endpoint}/${payload.pk}/`);
  }
}

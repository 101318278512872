import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { SecondaryUser } from '@/models/secondaryUser';
import { SecondaryUserService } from '@/services/secondaryUser/index';
import { Auth } from '@/models/auth';

const secondaryUserService = new SecondaryUserService();

export const state = {
  secondaryUser: [] as SecondaryUser[],
};

export type SecondaryUserState = typeof state;

export const mutations: MutationTree<SecondaryUserState> = {
  SET_SECONDARY_USER(state: any, payload: SecondaryUser[]) {
    state.secondaryUser = payload;
  },

  ADD_SECONDARY_USER(state: any, payload: SecondaryUser) {
    state.secondaryUser.push(payload);
  },

  UPDATE_SECONDARY_USER(state: any, payload: SecondaryUser) {
    const index = state.secondaryUser.findIndex(
      (model: SecondaryUser) => model.id === payload.id
    );
    state.secondaryUser.splice(index, 1, payload);
  },

  REMOVE_SECONDARY_USER(state: any, payload: number) {
    const index = state.secondaryUser.findIndex(
      (model: SecondaryUser) => model.id === payload
    );
    state.secondaryUser.splice(index, 1);
  },

  RESET_SECONDARY_USER(states: any) {
    const state = {
      secondaryUser: [] as SecondaryUser[],
    };
    Object.assign(states, state);
  },
};

export const actions: ActionTree<SecondaryUserState, SecondaryUserState> = {
  fetchSecondaryUsers: async ({ commit }: any, payload: any) =>
    secondaryUserService.fetchSecondaryUsers().then((data) => {
      commit('SET_SECONDARY_USER', data);
    }),

  createSecondaryUser: async ({ commit }: any, payload: SecondaryUser) =>
    secondaryUserService.createSecondaryUser(payload),
  //.then((model: SecondaryUser) => commit('ADD_SECONDARY_USER', model)),

  fetchSecondaryUser: async ({ state, commit }: any, payload: SecondaryUser) =>
    secondaryUserService
      .fetchSecondaryUser(payload)
      .then((model: SecondaryUser) => {
        const index = state.secondaryUser.findIndex(
          (v: SecondaryUser) => v.id === model.id
        );
        if (index === -1) {
          commit('ADD_SECONDARY_USER', model);
        } else {
          commit('UPDATE_SECONDARY_USER', model);
        }
      }),

  updateSecondaryUser: async (
    { state, commit }: any,
    payload: { inventory: number; id: number; data: SecondaryUser }
  ) => secondaryUserService.updateSecondaryUser(payload),
  // .then((model:SecondaryUser) => {
  //   const index = state.secondaryUser.findIndex((v:SecondaryUser) => v.id === model.id);
  //   if (index === -1) {
  //     commit('ADD_SECONDARY_USER', model);
  //   } else {
  //     commit('UPDATE_SECONDARY_USER', model);
  //   }
  // }),

  deleteSecondaryUser: async ({ state, commit }: any, payload: SecondaryUser) =>
    secondaryUserService.deleteSecondaryUser(payload).then(() => {
      commit('REMOVE_SECONDARY_USER', payload);
    }),
};

export const getters: GetterTree<SecondaryUserState, SecondaryUserState> = {};

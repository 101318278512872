import { axios } from '@/plugins/axios';
import { Auth } from '../../models/auth';
import { TokenService } from '../token';
import { AxiosInstance } from 'axios';

const tokenService = new TokenService();

export class AuthService {
  private endpoint: string;
  api: AxiosInstance;
  constructor(api = axios) {
    this.api = api;
    this.endpoint = ' https://ournationexpress.com/api/v1/user/';
  }

  async register(payload: Auth) {
    return this.api
      .post<Auth>(`${this.endpoint}registration/`, payload, {
        headers: {
          Authorization: null
        }
      })
      .then(({ data }) => {
        return data;
      });
  }

  async login(email: string, password: string) {
    return this.api
      .post(
        `${this.endpoint}login/`,
        {
          email: email,
          password: password
        },
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(({ data }) => {
        localStorage.setItem('user', JSON.stringify(data.user));
        tokenService.setToken(data.access_token); //TODO: check logic for login as it seems to set token in service and also in store
        tokenService.setRefreshToken(data.refresh_token);

        data.tokenData = tokenService.decodeToken(data.access_token);
        return data;
      });
  }

  async forgetPassword(email: string) {
    return this.api
      .post(
        `${this.endpoint}password/reset/`,
        {
          email: email
        },
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(({ data }) => {
        return data;
      });
  }

  async refreshToken(refreshToken: string) {
    return this.api
      .post(
        `https://ournationexpress.com/api/token/refresh/`,
        {
          refresh: refreshToken
        },
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(({ data }) => {
        tokenService.setToken(data.access); //TODO: check logic for login as it seems to set token in service and also in store
      });
  }

  async resetPassword(payload: any) {
    return this.api
      .post(`${this.endpoint}reset/${payload.urlToken}/`, payload)
      .then(({ data }) => {
        return data;
      });
  }

  async logout() {
    return this.api.post(`${this.endpoint}logout/`).finally(() => {
      delete this.api.defaults.headers.common['Authorization'];
    });
  }
}

import { axios } from '@/plugins/axios';
import { SecondaryUser } from '@/models/secondaryUser';

export class SecondaryUserService {
  private endpoint: string;

  constructor() {
    this.endpoint = '/secondaryuser.json';
  }

  async createSecondaryUser(secondaryUser: SecondaryUser) {
    console.log(secondaryUser);

    // return axios
    //   .post<SecondaryUser>(`${this.endpoint}${secondaryUser}`, secondaryUser)
    //   .then(({ data }) => data);
  }

  async fetchSecondaryUsers() {
    return axios
      .get<SecondaryUser[]>(`${this.endpoint}/`)
      .then(({ data }) => data);
  }

  async fetchSecondaryUser(secondaryUser: SecondaryUser) {
    return axios
      .get<SecondaryUser>(
        `${this.endpoint}${secondaryUser}/items/${secondaryUser.id}/`
      )
      .then(({ data }) => data);
  }

  async updateSecondaryUser(secondaryUser: any) {
    console.log(secondaryUser);

    // return axios
    //   .put<SecondaryUser>(`${this.endpoint}${secondaryUser.inventory}/items/${secondaryUser.id}/`, secondaryUser.data)
    //   .then(({ data }) => data);
  }

  async deleteSecondaryUser(secondaryUser: SecondaryUser) {
    return axios.delete(
      `${this.endpoint}${secondaryUser}/items/${secondaryUser.id}/`
    );
  }
}

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Address } from '@/models/address';
import { AddressService } from '@/services/address/index';

const addressService = new AddressService();

export const state = {
  address: [] as Address[]
};

export type AddressState = typeof state;

export const mutations: MutationTree<AddressState> = {
  SET_ADDRESS(state: any, payload: Address) {
    state.address = payload;
  },

  ADD_ADDRESS(state: any, payload: Address) {
    state.address.push(payload);
  },

  UPDATE_ADDRESS(state: any, payload: Address) {
    const index = state.address.findIndex(
      (model: Address) => model.pk === payload.pk
    );
    state.address.splice(index, 1, payload);
  },

  REMOVE_ADDRESS(state: any, payload: number) {
    const index = state.address.findIndex(
      (model: Address) => model.pk === payload
    );
    state.address.splice(index, 1);
  },
};

export const actions: ActionTree<AddressState, AddressState> = {

    createPickupAddress: async ({ commit }: any,  payload:any) =>
      addressService
        .createPickupAddress(payload)
        .then((model:Address) => commit('ADD_ADDRESS', model)),

    createUserAddress: async ({ commit }: any,  payload:any) =>
        addressService
          .createUserAddress(payload),

    createAddress: async ({ commit }: any,  payload:Address) =>
      addressService
        .createAddress(payload)
        .then((model:Address) => commit('ADD_ADDRESS', model)),
  
    fetchAddress: async ({ state, commit }: any) =>
      addressService.fetchAddress().then((model: Address) => {
        commit('SET_ADDRESS', model);
      }),
  
    updateAddress: async ({ state, commit }: any, payload: Address) =>
      addressService.updateAddress(payload).then((model:Address) => {
        const index = state.address.findIndex((v:Address) => v.pk === model.pk);
        if (index === -1) {
          commit('ADD_ADDRESS', model);
        } else {
          commit('UPDATE_ADDRESS', model);
        }
      }),
  
    deleteAddress: async ({ state, commit }: any, payload:Address) =>
      addressService.deleteAddress(payload).then(() => {
        commit('REMOVE_ADDRESS', payload);
      }),
};

export const getters: GetterTree<AddressState, AddressState> = {

};

import Vue, { VueConstructor } from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
      theme: {
        dark: false,
        default: 'light',
        disable: false,
        themes: {
          light: {
            primary: '#3492CF',
            secondary: '#EEF1FF',
            accent: '#176AE6',
            error: '#FF5252',
            info: '#2196F3',
            header: '#304967',
            success: '#4CAF50',
            warning: '#FFC107'
          },
          dark: {
            primary: '#3492CF',
            secondary: '#EEF1FF',
            accent: '#176AE6',
            error: '#FF5252',
            info: '#2196F3',
            header: '#304967',
            success: '#4CAF50',
            warning: '#FFC107'
          }
        }
      }
    });
  
  export function useVuetify(Vue: VueConstructor) {
    Vue.use(Vuetify);
  }
  
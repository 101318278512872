






import Vue from 'vue';
import { waitForReadystate } from '@/utils/componentHelpers';

export default Vue.extend({
  name: 'App',

  async mounted() {
    if (!this.$route.hash) return;

    await this.$nextTick();
    await waitForReadystate();
  },
  methods: {}
});

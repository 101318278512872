import axios from 'axios';
import { Notification } from '@/models/notification';

export class NotificationService {
  private endpoint: string;
  private userId!: string;

  constructor() {
    this.endpoint = ' https://ournationexpress.com/api/v1/notification';
  }

  async createNotification(notification: Notification) {
    return axios
      .post<Notification>(`${this.endpoint}/`, notification)
      .then(({ data }) => data);
  }

  async fetchNotifications() {
    return axios
      .get<Notification[]>(`${this.endpoint}/`)
      .then(({ data }) => data);
  }

  async updateNotification(notification: Notification) {
    return axios
      .put<Notification>(`${this.endpoint}/${notification.pk}/`, notification)
      .then(({ data }) => data);
  }

  async deleteNotification(notification: Notification) {
    return axios
      .delete(`${this.endpoint}/${notification.pk}/`)
      .then(({ data }) => data);
  }
}

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { StatisticsService } from '@/services/statistics';
import { Statistics } from '@/models/statistics';

import { IFetchStatsSummaryParams } from '@/services/statistics/service';
import { IFetchShipmentParams } from '@/models/shipment';

const statisticsService = new StatisticsService();

export const state = {
  statistics: {} as Statistics,
  outstanding: 0 as number
};

export type StatisticsState = typeof state;

export const mutations: MutationTree<StatisticsState> = {
  SET_STATISTICS_SUMMARY(state: any, payload: Statistics) {
    state.statistics = payload;
  },

  SET_STATISTICS_OUTSTANDING_BALANCE(state: any, payload: number) {
    state.outstanding = payload;
  }
};

export const actions: ActionTree<StatisticsState, StatisticsState> = {
  fetchStatisticsSummary: async (
    { state, commit }: any,
    payload: IFetchStatsSummaryParams
  ) =>
    statisticsService
      .fetchStatisticsSummary(payload)
      .then((model: Statistics) => {
        commit('SET_STATISTICS_SUMMARY', model);
      }),

  fetchStatisticsOutstandingBalance: async (
    { state, commit }: any,
    payload?: IFetchShipmentParams
  ) =>
    statisticsService
      .fetchStatisticsOutstandingBalance(payload)
      .then((amount: number) => {
        commit('SET_STATISTICS_OUTSTANDING_BALANCE', amount);
      })
};

export const getters: GetterTree<StatisticsState, StatisticsState> = {
  getOutstandingBalance: (state: StatisticsState): number => {
    return state.outstanding;
  },

  getStatistics: (state: StatisticsState): Statistics => {
    return state.statistics;
  }
};

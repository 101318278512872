import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Franchise } from '@/models/franchise';
import { FranchiseService } from '@/services/franchise';

const franchiseService = new FranchiseService();

export const state = {
  franchise: [] as Franchise[],
  userFranchise: [] as Franchise[],
};

export type FranchiseState = typeof state;

export const mutations: MutationTree<FranchiseState> = {
  SET_USER_FRANCHISE(state: any, payload: Franchise[]) {
    state.userFranchise = payload;
  },

  SET_FRANCHISE(state: any, payload: Franchise[]) {
    state.franchise = payload;
  },

  ADD_FRANCHISE(state: any, payload: Franchise) {
    state.franchise.push(payload);
  },

  UPDATE_FRANCHISE(state: any, payload: Franchise) {
    const index = state.franchise.findIndex(
      (model: Franchise) => model.pk === payload.pk
    );
    state.franchise.splice(index, 1, payload);
  },

  REMOVE_FRANCHISE(state: any, payload: number) {
    const index = state.franchise.findIndex(
      (model: Franchise) => model.pk === payload
    );
    state.franchise.splice(index, 1);
  },
};

export const actions: ActionTree<FranchiseState, FranchiseState> = {
  fetchUserFranchises: async ({ commit }: any, payload: any) =>
    franchiseService.fetchUserFranchises().then((data) => {
      commit('SET_USER_FRANCHISE', data);
    }),

  fetchFranchises: async ({ commit }: any, payload: any) =>
    franchiseService.fetchFranchises().then((data) => {
      commit('SET_FRANCHISE', data);
    }),

  createFranchise: async ({ commit }: any, payload: Franchise) => {
    return franchiseService
      .createFranchise(payload)
      .then((model: Franchise) => {
        commit('ADD_FRANCHISE', model);
        return model;
      });
  },

  fetchFranchise: async ({ state, commit }: any, payload: Franchise) =>
    franchiseService.fetchFranchise(payload).then((model: Franchise) => {
      const index = state.franchise.findIndex(
        (v: Franchise) => v.pk === model.pk
      );
      if (index === -1) {
        commit('ADD_FRANCHISE', model);
      } else {
        commit('UPDATE_FRANCHISE', model);
      }
    }),

  updateFranchise: async ({ state, commit }: any, payload: Franchise) =>
    franchiseService.updateFranchise(payload).then((model: Franchise) => {
      const index = state.franchise.findIndex(
        (v: Franchise) => v.pk === model.pk
      );
      if (index === -1) {
        commit('ADD_FRANCHISE', model);
      } else {
        commit('UPDATE_FRANCHISE', model);
      }

      return model;
    }),

  deleteFranchise: async ({ state, commit }: any, payload: Franchise) =>
    franchiseService.deleteFranchise(payload).then(() => {
      commit('REMOVE_FRANCHISE', payload.pk);
    }),
};

export const getters: GetterTree<FranchiseState, FranchiseState> = {};

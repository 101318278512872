import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Auth } from '@/models/auth';
import { AuthService } from '@/services/auth/index';
import { axios } from '@/plugins/axios';

const authService = new AuthService(axios);

export const state = {
  auth: [] as Auth[],
  accessToken: '',
  refreshToken: '',
  expiryTime: '',

  user: JSON.parse(localStorage.getItem('user')!) || null,

  namespaced: true,
};

export type AuthState = typeof state;

export const mutations: MutationTree<AuthState> = {
  REGISTER_SUCCESFUL(state: any, payload: any) {
    state.auth = payload;
  },

  // TOKEN
  SET_ACCESS_TOKEN(state: any, token: string) {
    state.auth.accessToken = token;
  },

  SET_REFRESH_TOKEN(state: any, token: string) {
    state.auth.refreshToken = token;
  },

  SET_EXPIRY_TIME(state: any, time: number) {
    state.auth.expiryTime = time;
  },

  SET_USER(state: any, payload: any) {
    state.user = payload;
  },
};

export const actions: ActionTree<AuthState, AuthState> = {
  register: async ({ commit }: any, payload: Auth) =>
    authService.register(payload).then((data) => {
      return data;
    }),

  login: async (
    { commit }: any,
    payload: { email: string; password: string }
  ) =>
    authService.login(payload.email, payload.password).then((response: any) => {
      commit('SET_USER', response.user);
      commit('SET_ACCESS_TOKEN', response.access_token);
    }),

  logout: async (
    { commit }: any,
    payload: { email: string; password: string }
  ) =>
    authService.logout().finally(() => {
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('remove_token');
      window.localStorage.removeItem('user');
    }),

  resetPassword: async ({ commit }: any, payload: any) =>
    authService.resetPassword(payload).then((data) => {
      return data;
    }),

  forgetPassword: async ({ commit }: any, payload: string) =>
    authService.forgetPassword(payload),
};

export const getters: GetterTree<AuthState, AuthState> = {
  getAuthById: (state: AuthState) => (id: number) => {
    return state.auth.find((model) => model.id === id);
  },
};

import { IFetchShipmentParams } from '@/models/shipment';
import axios from 'axios';
import { Statistics } from '../../models/statistics';

export interface IFetchStatsSummaryParams {
  franchise: number;
}

export class StatisticsService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'https://ournationexpress.com/api/v1/statistics';
  }

  async fetchStatisticsSummary(params: IFetchStatsSummaryParams) {
    return axios
      .get<Statistics[]>(`${this.endpoint}/summary/`, {
        params: params
      })
      .then(({ data }: any) => data);
  }

  async fetchStatisticsOutstandingBalance(params?: IFetchShipmentParams) {
    return axios
      .get<number>(`${this.endpoint}/outstandingbalance/`, {
        params: params
      })
      .then(({ data }: any) => data);
  }
}

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { AuthorizedPickupPerson } from '@/models/authorizedPickupPerson';
import { AuthorizedPickupPersonService } from '@/services/authorizedPickupPerson/index';

const authorizedPickupPersonService = new AuthorizedPickupPersonService();

export const state = {
  authorizedPickupPerson: [] as AuthorizedPickupPerson[]
};

export type AuthorizedPickupPersonState = typeof state;

export const mutations: MutationTree<AuthorizedPickupPersonState> = {
  SET_AUTHORIZED_PICKUP_PERSONS(state: any, payload: AuthorizedPickupPerson[]) {
    state.authorizedPickupPerson = payload;
  },

  ADD_AUTHORIZED_PICKUP_PERSON(state: any, payload: AuthorizedPickupPerson) {
    state.authorizedPickupPerson.push(payload);
  },

  UPDATE_AUTHORIZED_PICKUP_PERSON(state: any, payload: AuthorizedPickupPerson) {
    const index = state.authorizedPickupPerson.findIndex(
      (model: AuthorizedPickupPerson) => model.pk === payload.pk
    );
    state.authorizedPickupPerson.splice(index, 1, payload);
  },

  REMOVE_AUTHORIZED_PICKUP_PERSON(state: any, payload: number) {
    const index = state.authorizedPickupPerson.findIndex(
      (model: AuthorizedPickupPerson) => model.pk === payload
    );
    state.authorizedPickupPerson.splice(index, 1);
  },
};

export const actions: ActionTree<AuthorizedPickupPersonState, AuthorizedPickupPersonState> = {
    fetchAuthorizedPickupPersons: async ({ commit }: any, payload: any) =>
    authorizedPickupPersonService
      .fetchAuthorizedPickupPersons().then((data) => {
          commit('SET_AUTHORIZED_PICKUP_PERSONS', data)
      }),

    createAuthorizedPickupPerson: async ({ commit }: any,  payload:AuthorizedPickupPerson) =>
      authorizedPickupPersonService
        .createAuthorizedPickupPerson(payload)
        .then((model:AuthorizedPickupPerson) => commit('ADD_AUTHORIZED_PICKUP_PERSON', model)),
  
    fetchAuthorizedPickupPerson: async ({ state, commit }: any, payload:AuthorizedPickupPerson) =>
      authorizedPickupPersonService.fetchAuthorizedPickupPerson(payload).then((model:AuthorizedPickupPerson) => {
        const index = state.authorizedPickupPerson.findIndex((v:AuthorizedPickupPerson) => v.pk === model.pk);
        if (index === -1) {
          commit('ADD_AUTHORIZED_PICKUP_PERSON', model);
        } else {
          commit('UPDATE_AUTHORIZED_PICKUP_PERSON', model);
        }
      }),
  
    updateAuthorizedPickupPerson: async ({ state, commit }: any, payload: AuthorizedPickupPerson) =>
      authorizedPickupPersonService.updateAuthorizedPickupPerson(payload).then((model:AuthorizedPickupPerson) => {
        const index = state.authorizedPickupPerson.findIndex((v:AuthorizedPickupPerson) => v.pk === model.pk);
        if (index === -1) {
          commit('ADD_AUTHORIZED_PICKUP_PERSON', model);
        } else {
          commit('UPDATE_AUTHORIZED_PICKUP_PERSON', model);
        }
      }),
  
    deleteAuthorizedPickupPerson: async ({ state, commit }: any, payload:AuthorizedPickupPerson) =>
      authorizedPickupPersonService.deleteAuthorizedPickupPerson(payload).then(() => {
        commit('REMOVE_AUTHORIZED_PICKUP_PERSON', payload);
      }),
};

export const getters: GetterTree<AuthorizedPickupPersonState, AuthorizedPickupPersonState> = {

};

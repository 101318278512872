import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { AccountService } from '@/services/accounts';
import { Account } from '@/models/account';
import { Auth } from '@/models/auth';

const accountService = new AccountService();

export const state = {
  account: [] as Account[],
};

export type AccountState = typeof state;

export const mutations: MutationTree<AccountState> = {
  SET_ACCOUNT(state: any, payload: Account[]) {
    state.account = payload;
  },
};

export const actions: ActionTree<AccountState, AccountState> = {
  fetchAccount: async ({ state, commit }: any, payload: number) =>
    accountService.fetchAccount(payload).then((model: Account) => {
      commit('SET_ACCOUNT', model);
      return model;
    }),
};

export const getters: GetterTree<AccountState, AccountState> = {};

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { UserService } from '@/services/user';
import { Account, IAccountParams } from '@/models/account';
import { Auth } from '@/models/auth';

const userService = new UserService();

export const state = {
  count: 0,
  lastPromise: null,
  user: [] as Account[],
  superAdminUsers: [] as Account[]
};

export type UserState = typeof state;

export const mutations: MutationTree<UserState> = {
  SET_COUNT(state: any, payload: number): void {
    state.count = payload;
  },

  INCREMENT_COUNT(state: any): void {
    state.count += 1;
  },

  DECREMENT_COUNT(state: any): void {
    state.count -= 1;
  },

  SET_LAST_PROMISE(state: any, payload: Promise<any>): void {
    state.lastPromise = payload;
  },

  SET_SA_USERS(state: any, payload: Account[]) {
    state.superAdminUsers = payload;
  },

  SET_USERS(state: any, payload: Account[]) {
    state.user = payload;
  },

  ADD_USER(state: any, payload: Account) {
    state.user.push(payload);
  },

  UPDATE_USER(state: any, payload: Account) {
    const index = state.user.findIndex(
      (model: Account) => model.pk === payload.pk
    );
    state.user.splice(index, 1, payload);
  },

  REMOVE_USER(state: any, payload: number) {
    const index = state.user.findIndex(
      (model: Account) => model.pk === payload
    );
    state.user.splice(index, 1);
  }
};

export const actions: ActionTree<UserState, UserState> = {
  fetchSuperAdminUsers: async ({ state, commit }: any) =>
    userService.fetchSuperAdminUsers().then((model: Account) => {
      commit('SET_SA_USERS', model);
    }),

  fetchUsers: async ({ state, commit }: any, params?: IAccountParams) => {
    const promise = userService.fetchUsers(params);

    if (params?.noStoreUpdate) {
      return promise;
    }

    return promise.then((data) => {
      commit('SET_COUNT', data.count);
      commit('SET_USERS', data.results);
    });
  },

  fetchUser: async ({ state, commit }: any, payload: number) =>
    userService.fetchUser(payload).then((model: Account) => {
      commit('SET_USERS', model);
      return model;
    }),

  updateUser: async (
    { state, commit }: any,
    payload: { pk: number; data: Auth }
  ) =>
    userService.updateUser(payload).then((model: Account) => {
      const index = state.user.findIndex((v: Account) => v.pk === model.pk);
      if (index === -1) {
        commit('ADD_USER', model);
      } else {
        commit('UPDATE_USER', model);
      }
    }),

  deleteUser: async ({ state, commit }: any, payload: Account) =>
    userService.deleteUser(payload).then(() => {
      commit('REMOVE_USER', payload.pk);
    })
};

export const getters: GetterTree<UserState, UserState> = {
  getUserCount(state: UserState): number {
    return state.count;
  },

  getUsers(state: UserState): Account[] {
    return state.user;
  }
};

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { PreAlert } from '@/models/preAlert';
import { PreAlertService } from '@/services/preAlert';

const preAlertService = new PreAlertService();

export const state = {
  preAlert: [] as PreAlert[]
};

export type PreAlertState = typeof state;

export const mutations: MutationTree<PreAlertState> = {
  SET_PRE_ALERT(state: any, payload: PreAlert[]) {
    state.preAlert = payload;
  },

  ADD_PRE_ALERT(state: any, payload: PreAlert) {
    state.preAlert.push(payload);
  },

  UPDATE_PRE_ALERT(state: any, payload: PreAlert) {
    const index = state.preAlert.findIndex(
      (model: PreAlert) => model.id === payload.id
    );
    state.preAlert.splice(index, 1, payload);
  },

  REMOVE_PRE_ALERT(state: any, payload: number) {
    const index = state.preAlert.findIndex(
      (model: PreAlert) => model.id === payload
    );
    state.preAlert.splice(index, 1);
  }
};

export const actions: ActionTree<PreAlertState, PreAlertState> = {
  fetchPreAlerts: async ({ commit }: any, payload: any) =>
    preAlertService.fetchPreAlerts().then((data) => {
      commit('SET_PRE_ALERT', data);
    }),

  createPreAlert: async ({ commit }: any, payload: PreAlert) => {
    return preAlertService.createPreAlert(payload).then((model: PreAlert) => {
      commit('ADD_PRE_ALERT', model);
      return model;
    });
  },

  fetchPreAlert: async ({ state, commit }: any, payload: PreAlert) =>
    preAlertService.fetchPreAlert(payload).then((model: PreAlert) => {
      const index = state.preAlert.findIndex(
        (v: PreAlert) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_PRE_ALERT', model);
      } else {
        commit('UPDATE_PRE_ALERT', model);
      }
    }),

  updatePreAlert: async ({ state, commit }: any, payload: PreAlert) =>
    preAlertService.updatePreAlert(payload).then((model: PreAlert) => {
      const index = state.preAlert.findIndex(
        (v: PreAlert) => v.id === model.id
      );
      if (index === -1) {
        commit('ADD_PRE_ALERT', model);
      } else {
        commit('UPDATE_PRE_ALERT', model);
      }

      return model;
    }),

  deletePreAlert: async ({ state, commit }: any, payload: PreAlert) =>
    preAlertService.deletePreAlert(payload).then(() => {
      commit('REMOVE_PRE_ALERT', payload.id);
    })
};

export const getters: GetterTree<PreAlertState, PreAlertState> = {};

import axios from 'axios';
import { AuthorizedPickupPerson } from '@/models/authorizedPickupPerson';

export class AuthorizedPickupPersonService {
  private endpoint: string;
  private userId!: string;

  constructor() {
    this.endpoint =
      ' https://ournationexpress.com/api/v1/authorized_pickUp_person';
  }

  async createAuthorizedPickupPerson(
    authorizedPickupPerson: AuthorizedPickupPerson
  ) {
    const loginUser = JSON.parse(localStorage.getItem('user')!);
    this.userId = loginUser == null ? null : loginUser.pk;

    authorizedPickupPerson.user = this.userId;
    return axios
      .post<AuthorizedPickupPerson>(`${this.endpoint}/`, authorizedPickupPerson)
      .then(({ data }) => data);
  }

  async fetchAuthorizedPickupPersons() {
    return axios
      .get<AuthorizedPickupPerson[]>(`${this.endpoint}/`)
      .then(({ data }) => data);
  }

  async fetchAuthorizedPickupPerson(
    authorizedPickupPerson: AuthorizedPickupPerson
  ) {
    return axios
      .get<AuthorizedPickupPerson>(
        `${this.endpoint}${authorizedPickupPerson}/items/${authorizedPickupPerson.pk}/`
      )
      .then(({ data }) => data);
  }

  async updateAuthorizedPickupPerson(
    authorizedPickupPerson: AuthorizedPickupPerson
  ) {
    return axios
      .put<AuthorizedPickupPerson>(
        `${this.endpoint}/${authorizedPickupPerson.pk}/`,
        authorizedPickupPerson
      )
      .then(({ data }) => data);
  }

  async deleteAuthorizedPickupPerson(
    authorizedPickupPerson: AuthorizedPickupPerson
  ) {
    return axios.delete(`${this.endpoint}/${authorizedPickupPerson.pk}/`);
  }
}

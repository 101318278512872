import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { UserAddressService } from '@/services/userAddress/index';
import { UserAddress } from '@/models/user_address';

const userAddressService = new UserAddressService();

export const state = {
  userAddress: [] as UserAddress[]
};

export type UserAddressState = typeof state;

export const mutations: MutationTree<UserAddressState> = {
  SET_USER_ADDRESS(state: any, payload: UserAddress) {
    state.userAddress = payload;
  },

  ADD_USER_ADDRESS(state: any, payload: UserAddress) {
    state.userAddress.push(payload);
  },

  UPDATE_USER_ADDRESS(state: any, payload: UserAddress) {
    const index = state.userAddress.findIndex(
      (model: UserAddress) => model.pk === payload.pk
    );
    state.userAddress.splice(index, 1, payload);
  },

  REMOVE_USER_ADDRESS(state: any, payload: number) {
    const index = state.userAddress.findIndex(
      (model: UserAddress) => model.pk === payload
    );
    state.userAddress.splice(index, 1);
  },
};

export const actions: ActionTree<UserAddressState, UserAddressState> = {

    createUserAddress: async ({ commit }: any,  payload:any) =>
        userAddressService
          .createUserAddress(payload),

    fetchUserAddresses: async ({ state, commit }: any) =>
      userAddressService.fetchUserAddresses().then((model: UserAddress) => {
        commit('SET_USER_ADDRESS', model);
      }),
  
    updateUserAddress: async ({ state, commit }: any, payload: UserAddress) =>
      userAddressService.updateUserAddress(payload).then((model:UserAddress) => {
        const index = state.userAddress.findIndex((v:UserAddress) => v.pk === model.pk);
        if (index === -1) {
          commit('ADD_USER_ADDRESS', model);
        } else {
          commit('UPDATE_USER_ADDRESS', model);
        }
      }),
  
    deleteUserAddress: async ({ state, commit }: any, payload:UserAddress) =>
      userAddressService.deleteUserAddress(payload).then(() => {
        commit('REMOVE_USER_ADDRESS', payload);
      }),
};

export const getters: GetterTree<UserAddressState, UserAddressState> = {

};

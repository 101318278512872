import camelCase from 'lodash/camelCase';

const modules: any = {};
const requireModule = require.context('.', false, /\.ts$/);
// Dont regiter this file as a vuex module index.ts etc
requireModule.keys().forEach(fileName => {
  if (fileName === './index.ts') return;
  const moduleName = camelCase(fileName.replace(/(\.\/|\.ts)/g, ''));
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName)
  };
});

export default modules;
